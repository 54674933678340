'use client';

import FilterSVG from '@/icons/FilterSVG';

import { logAnalyticsEvent } from '@/libs/firebase/log-analytics-event';

import { FirebaseEventNameEnum } from '@/enums/firebase-event-name.enums';

import { IconButton } from '@/components/Buttons';
import { NavLink } from '@/components/NavLink';

import { CategoryToggler } from '@/layout/DesktopMenu/CategoryPanel';
import styles from '@/layout/DesktopMenu/SearchCategory.module.scss';
import { SearchBar } from '@/layout/SearchBar/SearchBar';

import { useStore } from '@/contexts/StoreContext';

export const SearchCategory = () => {
  const { isSearchBarVisible, toggleSearchBar } = useStore(state => state);

  const handleLogger = () => {
    logAnalyticsEvent(FirebaseEventNameEnum.NAV_LINKS, {
      href: '/movies/all',
      title: 'Filter',
    });
  };

  return (
    <div className={styles.searchCategoryContainer}>
      <CategoryToggler />
      <SearchBar toggleSearchBar={toggleSearchBar} isSearchBarVisible={isSearchBarVisible} />
      <NavLink
        href="/movies/all"
        className={styles.categoryButton}
        activeClassName={styles.active}
        checkFirstPart
        onClick={handleLogger}
      >
        <FilterSVG width={20} height={20} />
      </NavLink>
    </div>
  );
};
