import { useSearchParamsResult } from '@/hooks/useSearchParamsResult';
import { useNotificationsDetail } from '@/views/NotificationDetailView/useNotificationDetail';

import { CardImage } from '@/components/Card';

import styles from '@/layout/NotificationToggler/NotificationsSingle.module.scss';
import { NotificationSingleSkeleton } from '@/layout/NotificationToggler/NotificationsSingleSkeleton';

// =================================================================

const CARD_ASPECT_RATIO = 341 / 426;

// =================================================================

export const NotificationSingle = () => {
  const { queryParams } = useSearchParamsResult();
  const { notification_id: notificationId } = queryParams as { notification_id: string };

  const { data: notification, isPending } = useNotificationsDetail(notificationId as string);

  if (isPending) return <NotificationSingleSkeleton />;

  return (
    <div className={styles.content}>
      <CardImage
        as="div"
        aspectRatio={CARD_ASPECT_RATIO}
        src={notification?.image || ''}
        alt={notification?.title || ''}
        className={styles.cardImage}
        imageWrapperClassName={styles.imageContainer}
      />

      <div className={styles.info}>
        <h3 className={styles.title}>{notification?.title}</h3>
        <p className={styles.description}>{notification?.full_text}</p>
      </div>
    </div>
  );
};
