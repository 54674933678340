'use client';

import { AnalyticsCallOptions, CustomEventName, logEvent } from 'firebase/analytics';

import { analyticsPromise } from '@/libs/firebase';

// =================================================================

export const logAnalyticsEvent = async <T extends string>(
  eventName: CustomEventName<T>,
  eventParams?: {
    [key: string]: any;
  },
  options?: AnalyticsCallOptions,
) => {
  const baseUrl = typeof window !== 'undefined' ? window.location.origin : '';

  if (
    process.env.NODE_ENV === 'production' &&
    baseUrl === process.env.NEXT_PUBLIC_CINERAMA_WEB_URL
  ) {
    const analytics = await analyticsPromise;
    if (analytics) {
      return logEvent(analytics, eventName, eventParams, options);
    }
  } else {
    // console.warn(`[DEV] logEvent: ${eventName}`, options);
  }
};
