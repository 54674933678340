import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { User } from '@/app/actions/auth/types';
import DoubleCheckSVG from '@/icons/DoubleCheckSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';
import { useNotifications } from '@/layout/NotificationToggler/useNotifications';

import { NotificationApi } from '@/api/domains/notification.api';

import { Button } from '@/components/Buttons';

import styles from '@/layout/NotificationToggler/ReadAllButton.module.scss';

// =================================================================

interface ReadAllButoonProps {
  afterReadAllCallback: () => void;
}

// =================================================================

export const ReadAllButoon = (props: ReadAllButoonProps) => {
  const { afterReadAllCallback } = props;
  const { userCredentials, setUserCredentials } = useVerification();
  const { refetch } = useNotifications();

  const onReadAll = async () => {
    await NotificationApi.readAllNotifications();
    refetch().then(() => {
      const userData = { ...userCredentials, notifications: 0 } as User;
      setUserCredentials(userData);
      afterReadAllCallback();
    });
  };

  return (
    <div className={styles.container}>
      <Button as="button" variant="primary" size="xs" onClick={onReadAll}>
        <DoubleCheckSVG /> Прочитать все
      </Button>
    </div>
  );
};

// =================================================================

export const ReadAllButoonSkeleton = () => {
  return (
    <div className={styles.container}>
      <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={135} height={36}>
        <Skeleton count={1} />
      </SkeletonTheme>
    </div>
  );
};
