'use client';

import { Fragment } from 'react';
import { useKey } from 'react-use';

import clsx from 'clsx';

import CategorySVG from '@/icons/CategorySVG';

import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';

import { logAnalyticsEvent } from '@/libs/firebase/log-analytics-event';

import { FirebaseEventNameEnum } from '@/enums/firebase-event-name.enums';

import { FadeTransition } from '@/components/Animations';
import { Backdrop } from '@/components/Backdrop';
import { IconButton } from '@/components/Buttons';
import { Focusable } from '@/components/Focusable';

import styles from '@/layout/DesktopMenu/CategoryPanel.module.scss';

import { useStore } from '@/contexts/StoreContext';

import { CategoriesView } from '@/views/CategoriesView/CategoriesView';

export const CategoryToggler = () => {
  const { isCategoryVisible, toggleCategoryPanel } = useStore(state => state);

  useLockBodyScroll({ isLocked: isCategoryVisible });
  useKey('Escape', () => toggleCategoryPanel(false));

  return (
    <Focusable className={styles.sidebar}>
      <IconButton
        as="button"
        icon={CategorySVG}
        iconWidth={20}
        iconHeight={20}
        className={clsx(styles.categoryButton, {
          [styles.active]: isCategoryVisible,
        })}
        onClick={() => {
          toggleCategoryPanel(!isCategoryVisible);
          logAnalyticsEvent(FirebaseEventNameEnum.CLICK, {
            title: 'category-toggler',
          });
        }}
      />
      <FadeTransition inProp={isCategoryVisible} timeout={0}>
        <Fragment>
          <Backdrop onClick={() => toggleCategoryPanel(false)} className={styles.backdropSidebar} />
          <div className={styles.categoryContainer}>
            <div className={styles.content}>
              <CategoriesView />
            </div>
          </div>
        </Fragment>
      </FadeTransition>
    </Focusable>
  );
};
