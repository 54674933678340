import { Fragment, useEffect, useMemo } from 'react';

import clsx from 'clsx';

import NotificationsSVG from '@/icons/NotificationsSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';
import { useSearchParamsResult } from '@/hooks/useSearchParamsResult';

import { logAnalyticsEvent } from '@/libs/firebase/log-analytics-event';

import { FirebaseEventNameEnum } from '@/enums/firebase-event-name.enums';

import { FadeTransition } from '@/components/Animations';
import { BaseTransition } from '@/components/Animations/BaseTransition';
import { Backdrop } from '@/components/Backdrop';
import { Button } from '@/components/Buttons';
import { Portal } from '@/components/Portal';

import { Sidebar } from '@/layout/MobileMenu/SidebarToggler/Sidebar';
import styles from '@/layout/NotificationToggler/NotificationToggler.module.scss';
import { Notifications } from '@/layout/NotificationToggler/Notifications';

import { useStore } from '@/contexts/StoreContext';

// =================================================================

export const NotificationToggler = () => {
  const { userCredentials } = useVerification();
  const { hideNotification, isNotificationVisible, showNotification } = useStore(state => state);

  const { queryParams, removeQueryParams } = useSearchParamsResult();
  const { notification_id: notificationId } = queryParams as { notification_id: string };

  useEffect(() => {
    if (notificationId) {
      showNotification();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationId]);

  const handleCloseNotification = () => {
    removeQueryParams('notification_id');
    hideNotification();
  };

  const hasUnReadNotifications = useMemo(
    () => userCredentials?.notifications || 0,
    [userCredentials?.notifications],
  );

  const openNotification = () => {
    showNotification();
    logAnalyticsEvent(FirebaseEventNameEnum.CLICK, {
      title: 'open-notification-modal',
    });
  };

  return (
    <Fragment>
      <Button
        as="button"
        size="xs"
        variant="outline"
        onClick={openNotification}
        className={styles.notificationsIcon}
      >
        <NotificationsSVG
          width={25}
          height={25}
          className={clsx(hasUnReadNotifications && styles.hasUnReadNotifications)}
        />
        {Boolean(hasUnReadNotifications) && (
          <span className={styles.badge}>
            {Number(userCredentials?.notifications) > 9 ? '+9' : userCredentials?.notifications}
          </span>
        )}
      </Button>

      <Portal>
        <FadeTransition inProp={isNotificationVisible}>
          <Backdrop onClick={handleCloseNotification} className={styles.backdropSidebar} />
        </FadeTransition>
        <BaseTransition
          inProp={isNotificationVisible}
          timeout={300}
          classNames={{
            enter: styles.slideEnter,
            enterActive: styles.slideEnterActive,
            exit: styles.slideExit,
            exitActive: styles.slideExitActive,
          }}
        >
          <Sidebar
            isOpen={isNotificationVisible}
            onClose={handleCloseNotification}
            style="dark"
            position="right"
          >
            <Notifications handleClose={handleCloseNotification} />
          </Sidebar>
        </BaseTransition>
      </Portal>
    </Fragment>
  );
};
