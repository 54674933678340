import clsx from 'clsx';

import ArrowRightSVG from '@/icons/ArrowRightSVG';
import CloseSVG from '@/icons/CloseSVG';

import { useSearchParamsResult } from '@/hooks/useSearchParamsResult';

import { Button } from '@/components/Buttons';

import styles from '@/layout/NotificationToggler/Notifications.module.scss';
import { NotificationsList } from '@/layout/NotificationToggler/NotificationsList';
import { NotificationSingle } from '@/layout/NotificationToggler/NotificationsSingle';

// =================================================================

interface NotificationsProps {
  handleClose: () => void;
}

// =================================================================

export const Notifications = (props: NotificationsProps) => {
  const { handleClose } = props;
  const { queryParams, removeQueryParams } = useSearchParamsResult();
  const { notification_id: notificationId } = queryParams as { notification_id: string };

  return (
    <aside className={styles.container}>
      <header className={styles.header}>
        <div className={styles.headerLeft}>
          {notificationId && (
            <Button
              as="button"
              size="xs"
              variant="secondary"
              shape="circle"
              onClick={() => removeQueryParams('notification_id')}
              className={clsx(styles.backButton, styles.rotate, 'p-1')}
            >
              <ArrowRightSVG width={16} height={16} />
            </Button>
          )}
          <h2 className={styles.title}>Уведомления</h2>
        </div>
        <Button
          as="button"
          size="xs"
          variant="secondary"
          shape="circle"
          onClick={handleClose}
          className={clsx(styles.backButton, 'p-1')}
        >
          <CloseSVG width={20} height={20} />
        </Button>
      </header>
      <main>
        {notificationId ? <NotificationSingle /> : <NotificationsList handleClose={handleClose} />}
      </main>
    </aside>
  );
};
