import clsx from 'clsx';

import { ClassNameProps } from '@/types/common.types';

import type { Notification } from '@/api/types/notification.types';

import {
  Card,
  CardBody,
  CardImage,
  CardSubtitle,
  CardTitle,
  useCardHover,
} from '@/components/Card';
import { Marquee } from '@/components/Marquee/Marquee';

import styles from '@/layout/NotificationToggler/NotificationCard.module.scss';

// =================================================================

const CARD_ASPECT_RATIO = 341 / 426;

// =================================================================

interface NotificationCardProps extends ClassNameProps {
  notification: Notification;
  handleClick?: () => void;
}

// =================================================================

export const NotificationCard = (props: NotificationCardProps) => {
  const { notification, className, handleClick } = props;

  const { ref, isHovered } = useCardHover<HTMLDivElement>();

  return (
    <Card ref={ref} className={className}>
      <div className={styles.date}>{notification.sent_date?.split(' ')[0]}</div>
      <div
        className={clsx(styles.notificationCard, { [styles.isRead]: !notification.is_read })}
        onClick={handleClick}
      >
        <CardImage
          as="div"
          aspectRatio={CARD_ASPECT_RATIO}
          className={styles.cardImage}
          imageWrapperClassName={styles.imageContainer}
          src={notification.image}
          alt={notification.title}
          imageClassName={styles.cardImage}
        >
          <span className={styles.time}>{notification.sent_date?.split(' ')[1]}</span>
        </CardImage>

        <CardBody className={styles.movieCardBody}>
          <CardTitle as="h3" lines={1} className={styles.title}>
            <Marquee isEnabled={isHovered}>{notification.title}</Marquee>
          </CardTitle>

          <CardSubtitle as="p" className={styles.description}>
            {notification.description}
          </CardSubtitle>
        </CardBody>
      </div>
    </Card>
  );
};
