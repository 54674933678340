import { useQueryClient } from '@tanstack/react-query';

import { produce } from 'immer';
import { useLocale } from 'next-intl';

import { Locale } from '@/types/common.types';

import { useVerification } from '@/contexts/VerificationContext/useVerification';
import { ResponseInfiniteLoaderData, useInfiniteLoader } from '@/hooks/useInfiniteLoader';

import { NotificationApi } from '@/api/domains/notification.api';
import { Notification } from '@/api/types/notification.types';

import { NotificationQueryKeys } from '@/contexts/QueryContext/query.keys';

// =================================================================

const selectFunction = <TQueryData>({ pages }: ResponseInfiniteLoaderData<TQueryData>) => {
  return {
    list: pages.reduce((prevValue: TQueryData[], currentValue) => {
      const newValue = currentValue.list.map(item => ({
        ...item,
        pageParam: currentValue.currentPage,
      }));

      return [...prevValue, ...newValue];
    }, []),
    total: pages[0].total,
    currentPage: pages[0].currentPage,
    lastPage: pages[0].lastPage,
    extra: pages[0].extra,
  };
};

// =================================================================

export const useNotifications = () => {
  const locale = useLocale() as Locale;
  const { isInitialCheckDone, userAccessToken } = useVerification();

  const { data, refetch, ...rest } = useInfiniteLoader<Notification, unknown>({
    queryKey: NotificationQueryKeys.list(locale),
    queryFn: ({ pageParam = 1 }) =>
      NotificationApi.fetchNotifications({ page: pageParam, limit: 5 }),
    initialPageParam: 1,
    select: selectFunction,
    enabled: isInitialCheckDone || Boolean(userAccessToken),
    staleTime: Infinity,
  });

  return { data, refetch, ...rest };
};

// =================================================================

export const useReadNotificationById = () => {
  const queryClient = useQueryClient();
  const locale = useLocale() as Locale;

  return (id: number, pageParam: number) => {
    const notificationsListKacheKey = NotificationQueryKeys.list(locale);

    queryClient.setQueryData(
      notificationsListKacheKey,
      (old: ResponseInfiniteLoaderData<Notification>) => {
        return produce(old, draft => {
          const notification = draft.pages[pageParam - 1].list.find(item => item.id === id);
          if (notification) {
            notification.is_read = true;
          }
        });
      },
    );
  };
};
