'use client';

// Import the functions you need from the SDKs you need
import { getAnalytics, isSupported, Analytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

// =================================================================

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

// =================================================================

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analyticsPromise: Promise<Analytics | null> = isSupported().then(supported => {
  if (supported) {
    return getAnalytics(app);
  } else {
    // console.warn('Firebase Analytics is not supported on this browser.');
    return null;
  }
});

export { app, analyticsPromise };
