/* eslint-disable @next/next/no-img-element */
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { Card, CardBody, CardImage, CardSubtitle, CardTitle } from '@/components/Card';

import styles from '@/layout/NotificationToggler/NotificationCardSkelton.module.scss';

// =================================================================

const CARD_ASPECT_RATIO = 341 / 426;

// =================================================================

export const NotificationCardSkelton = () => {
  return (
    <Card>
      <div className={styles.date}>
        <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={150}>
          <Skeleton count={1} />
        </SkeletonTheme>
      </div>
      <div className={styles.notificationCard}>
        <CardImage
          as="div"
          aspectRatio={CARD_ASPECT_RATIO}
          className={styles.cardImage}
          imageWrapperClassName={styles.imageContainer}
          src="/assets/images/movie-card-placeholder-xl.png"
          alt="movie-card-skelton"
          imageClassName={styles.cardImage}
        />

        <CardBody className={styles.cardBody}>
          <CardTitle as="h3" className={styles.title}>
            <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={190}>
              <Skeleton count={1} />
            </SkeletonTheme>
          </CardTitle>
          <CardSubtitle className={styles.description}>
            <SkeletonTheme baseColor="#0F0F12" highlightColor="#444" width={300}>
              <Skeleton count={2} />
            </SkeletonTheme>
          </CardSubtitle>
        </CardBody>
      </div>
    </Card>
  );
};
