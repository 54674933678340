'use client';

import { PrimaryNav } from '@/layout/DesktopMenu/PrimaryNav';

import { useStore } from '@/contexts/StoreContext';

export const SelectedMenu = () => {
  const { isSearchBarVisible } = useStore(state => state);

  if (!isSearchBarVisible) {
    return <PrimaryNav />;
  }

  return null;
};
