'use client';

import { useEffect, useState } from 'react';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { customLocalStorage } from '@/helpers/storage.helpers';

import { logAnalyticsEvent } from '@/libs/firebase/log-analytics-event';

import { USER_ACCESS_TOKEN } from '@/constants/user-credentials.constants';

import { FirebaseEventNameEnum } from '@/enums/firebase-event-name.enums';

import { Button } from '@/components/Buttons';

import { LoginButton } from '@/layout/DesktopMenu/LoginButton';
import styles from '@/layout/DesktopMenu/SecondaryNav.module.scss';
import { LanguageDropdown } from '@/layout/LanguageDropdown';
import { NotificationToggler } from '@/layout/NotificationToggler';
import { UserDropdownMenu } from '@/layout/UserDropdownMenu';

export const SecondaryNav = () => {
  const { userAccessToken, userCredentials } = useVerification();
  const [hasNotAccessToken, setHasNotAccessToken] = useState<boolean>(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasNotAccessToken(Boolean(customLocalStorage.getItem(USER_ACCESS_TOKEN)));
    }
  }, [userAccessToken]);

  const handleLogger = () => {
    logAnalyticsEvent(FirebaseEventNameEnum.NAV_LINKS, {
      href: '/subscriptions',
      title: 'Discount',
    });
  };

  return (
    <div className={styles.secondaryNav}>
      <NotificationToggler />
      <LanguageDropdown />

      {!hasNotAccessToken && (
        <Button
          title="Discount"
          as="link"
          href={'/subscriptions'}
          variant="primary"
          size={'md'}
          className={styles.navButton}
          onClick={handleLogger}
        >
          <span>30 дней за 900 сум</span>
        </Button>
      )}
      {userCredentials?.discount && (
        <Button
          title="Discount"
          as="link"
          href={'/subscriptions'}
          variant="primary"
          size={'md'}
          className={styles.navButton}
          onClick={handleLogger}
        >
          <span>30 дней за {userCredentials.discount_price} сум</span>
        </Button>
      )}
      {userAccessToken && <UserDropdownMenu />}
      {!userAccessToken && <LoginButton />}
    </div>
  );
};
