import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { CardImage } from '@/components/Card';

import styles from '@/layout/NotificationToggler/NotificationsSingle.module.scss';

// =================================================================

const CARD_ASPECT_RATIO = 409 / 409;

// =================================================================

export const NotificationSingleSkeleton = () => {
  return (
    <SkeletonTheme baseColor="#19191b" highlightColor="#444" width={80}>
      <div className={styles.content}>
        <CardImage
          as="div"
          aspectRatio={CARD_ASPECT_RATIO}
          src="/assets/images/movie-card-placeholder-xl.png"
          alt="movie-card-skelton"
        />
        <div className={styles.info}>
          <Skeleton width={190} className="mb-3" />
          <Skeleton width={345} count={4} className="mb-2" />
          <Skeleton width={345} count={4} className="mb-2" />
        </div>
      </div>
    </SkeletonTheme>
  );
};
