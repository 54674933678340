'use client';

import { useTranslations } from 'next-intl';

import { navigationList } from '@/app.config';
import DiscountSVG from '@/icons/DiscountSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { logAnalyticsEvent } from '@/libs/firebase/log-analytics-event';

import { FirebaseEventNameEnum } from '@/enums/firebase-event-name.enums';

import { NavLink } from '@/components/NavLink';
import { PromoCodeModal } from '@/components/PromoCodeModal/PromoCodeModal';

import styles from '@/layout/DesktopMenu/PrimaryNav.module.scss';

export const PrimaryNav = () => {
  const t = useTranslations('layout');

  const { userAccessToken } = useVerification();

  const handleLogger = (href: string, title: string) => {
    logAnalyticsEvent(FirebaseEventNameEnum.NAV_LINKS, {
      href,
      title,
    });
  };

  return (
    <ul className={styles.primaryNav}>
      {navigationList.map((item, index) => {
        if (item.isVisibleWhenAuth && !userAccessToken) return;
        return (
          <li key={index}>
            <NavLink
              href={item.href}
              data-test-id={item.linkTitle}
              className={styles.navLink}
              activeClassName={styles.active}
              checkFirstPart
              onClick={() => handleLogger(item.href, item.title)}
            >
              <span className={styles.activeIcon}>{item.icon}</span>
              {t(item.title)}
            </NavLink>
          </li>
        );
      })}

      <PromoCodeModal icon={<DiscountSVG height={20} width={20} />} />
    </ul>
  );
};
