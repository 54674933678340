import { Fragment, useCallback } from 'react';

import { User } from '@/app/actions/auth/types';

import { useVerification } from '@/contexts/VerificationContext/useVerification';
import { useSearchParamsResult } from '@/hooks/useSearchParamsResult';
import {
  useNotifications,
  useReadNotificationById,
} from '@/layout/NotificationToggler/useNotifications';

import { Notification } from '@/api/types/notification.types';

import { LoaderButton } from '@/components/LoaderButton';

import { NotificationCard } from '@/layout/NotificationToggler/NotificationCard';
import { NotificationCardSkelton } from '@/layout/NotificationToggler/NotificationCardSkelton';
import styles from '@/layout/NotificationToggler/NotificationsList.module.scss';
import { ReadAllButoon, ReadAllButoonSkeleton } from '@/layout/NotificationToggler/ReadAllButton';

// =================================================================

interface NotificationsListProps {
  handleClose: () => void;
}

// =================================================================

export const NotificationsList = (props: NotificationsListProps) => {
  const { handleClose } = props;
  const { data, handleButtonClick, hasNextPage, isLoading, ref, status } = useNotifications();
  const { userCredentials, setUserCredentials } = useVerification();

  const { mergeQueryParams } = useSearchParamsResult();

  const setNotificationsListData = useReadNotificationById();

  const { queryParams } = useSearchParamsResult();
  const { notification_id: notificationId } = queryParams as { notification_id: string };

  const handleClick = useCallback(
    (notification: Notification) => {
      mergeQueryParams({ notification_id: notification.id });

      if (userCredentials?.notifications) {
        setNotificationsListData(notification.id, notification?.pageParam || 1);
        const userData = {
          ...userCredentials,
          notifications: Number(userCredentials?.notifications) - 1,
        } as User;
        setUserCredentials(userData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userCredentials],
  );

  const isPending = status === 'pending';

  if (isPending && !data) {
    return (
      <Fragment>
        {!notificationId && Boolean(userCredentials?.notifications) && <ReadAllButoonSkeleton />}
        <ul className={styles.list}>
          {Array(5)
            .fill(null)
            .map((_, index) => (
              <li key={index}>
                <NotificationCardSkelton />
              </li>
            ))}
        </ul>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {!notificationId && Boolean(userCredentials?.notifications) && (
        <ReadAllButoon afterReadAllCallback={handleClose} />
      )}

      <ul className={styles.list}>
        {data?.list?.map(notification => (
          <li key={notification.id}>
            <NotificationCard
              notification={notification}
              handleClick={() => handleClick(notification)}
            />
          </li>
        ))}
        <li>
          {!isPending && hasNextPage && (
            <LoaderButton ref={ref} isLoading={isLoading} onClick={handleButtonClick} />
          )}
        </li>
      </ul>
    </Fragment>
  );
};
